import * as React from 'react'
import { HashRouter as Router, Route, Link, Routes, BrowserRouter } from 'react-router-dom'
import { render } from 'react-dom';
import {Home, Foo, Bar} from './Pages';
// import './style.css';


class App extends React.Component {
  render() {
    return (
      <div>
      <div>
      <BrowserRouter>  
      <Routes>
        <Route
          path="/"
          element={<div>Home</div>}          
        />
        <Route
          path="/foo"
          element={<div>Foo</div>}          
        />    
        <Route
          path="/poo"
          element={<div>Poo</div>}          
        />               
      </Routes> 
      </BrowserRouter>           
      </div>
      </div>
    );
  }
}

render(<App />, document.getElementById('root'));